body {
    background-color: #e5e5f7;
    box-sizing: border-box;
    background-image: url('/public/background.png');
    background-position: 0 0, 100px 100px;
    margin: 0;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

#root{
    flex: 1;
    padding: 2em 5vw;
}

h1{
    font-size: 6rem;
    margin: 0px;
    padding: 0px;
}

.hand {
    width: 5rem;
    height: 5rem;
    margin-right: 0.4rem;
}
.wheel {
    min-height: 100%;
    height: 0px;
    flex: 1;
    filter: drop-shadow(0px 0px 10px #000);   /*drop shadow through transparent png*/
}
.spin-btn { /*SPIN button*/
    margin-top: 1rem;
    background-color: lime;
    padding: 18px 18px;
    border: 3px solid black;
    border-radius: 40px;
    box-shadow: 0 5px 1.5px #999;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 1.6rem;
    user-select: none;
}
.spin-btn:hover {
    background-color: lime;
    cursor: pointer!important;
}

.reducted{ /*the reducted bet amount animation when you click spin*/
    position: absolute;
    top: 38.45rem;
    margin-left: 0.8rem;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 1.45rem;
    user-select: none;
    color: rgb(187, 1, 1);
    text-shadow: -0.4px 0.4px 0 black,
                 0.4px 0.4px 0 black,
                 0.4px -0.4px 0 black,
                 -0.4px -0.4px 0 black;
}
